<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('AdsBases')"
    @cancel="onCancel('AdsBases')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'AdsBasesCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'AdsBases',
      pageHeader: 'Создание базы АДС',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Базы АДС',
          route: { name: 'AdsBases' },
        },
        {
          text: 'Создание базы АДС',
        },
      ],
      initialForm: {
        base: null,
        customerId: null,
        accountId: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      customers: (state) => state.common.customers.data,
      employeeAccounts: (state) => state.common.employeeAccounts.data,
      sites: (state) => state.common.sites.data,
    }),

    computedSites() {
      if (
        this.sites &&
        this.sites.length &&
        (this.form.customerId || this.form.accountId)
      ) {
        return this.sites.map((site) => ({
          ...site,
          value: site.name,
        }));
      }
      return [];
    },

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'customerId',
              label: 'Клиент',
              list: this.customers,
              attributes: {
                clearable: true,
              },
              disabled: this.form.accountId ? true : false,
            },
            {
              type: 'select',
              key: 'base',
              label: 'Название базы',
              list: this.computedSites,
            },
            {
              type: 'select',
              key: 'accountId',
              label: 'Пользователь',
              list: this.employeeAccounts,
              attributes: {
                clearable: true,
              },
              disabled: this.form.customerId ? true : false,
            },
          ],
        },
      ];
    },
  },

  watch: {
    'form.customerId': {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.form.accountId = null;

          this.getCommonList({
            name: 'Sites',
            params: {
              customerId: this.form.customerId,
            },
          });
        } else if (!newVal && this.form && this.form.base) {
          this.form.base = null;
        }
      },
      immediate: true,
    },

    'form.accountId': {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.form.customerId = null;

          this.getCommonList({
            name: 'Sites',
          });
        } else if (!newVal && this.form && this.form.base) {
          this.form.base = null;
        }
      },
      immediate: true,
    },
  },

  created() {
    this.isLoadingPage = true;

    const customers = this.getCommonList({ name: 'Customers' });
    const employeeAccounts = this.getCommonList({ name: 'EmployeeAccounts' });

    Promise.all([customers, employeeAccounts]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
